import React from "react";
import "./TournamentMiddle.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import TournamentFirstCard from "./TournamentFirstCard/TournamentFirstCard";
import TournamentUpcoming from "./TournamentUpcoming/TournamentUpcoming";

const images = [
    { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Football" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Chess" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Hockey" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Football" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Kabaddi" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "baseball" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Vollyball" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Basketball" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Hockey" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Kabaddi" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Football" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "IceHockey" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "FootBall" },
    // { id: 1, imgSource: process.env.PUBLIC_URL + "/assets/images/home/sidebar/football.png", sportName: "Chess" },
]

const TournamentMiddle = () => {
    return (
        <>
            <section className="TournamentMiddle">
                <div className="">
                    <div className="card tournamentCard">
                        <div className="card-body">
                            <Swiper
                                // slidesPerView={12}
                                // spaceBetween={30}
                                freeMode={true}

                                modules={[FreeMode]}
                                className="mySwiper"

                                breakpoints={{
                                    380: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    380: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    575: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 6,
                                        spaceBetween: 10,
                                    },
                                    1024: {
                                        slidesPerView: 8,
                                        spaceBetween: 10,
                                    },
                                    1200: {
                                        slidesPerView: 8,
                                        spaceBetween: 10,
                                    },
                                    1400: {
                                        slidesPerView: 10,
                                        spaceBetween: 10,
                                    },
                                }}
                            >
                                {images.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="imgSportNamemain">
                                            <div className="imgMain">
                                                <img src={item.imgSource} alt={`Image ${index}`} />
                                            </div>
                                            <p>{item.sportName}</p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="tab-div">
                            <Nav variant="pills" className="">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Live</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">1H</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">3H</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="forth">12H</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">Weekend</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sixth">All Upcoming</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="middle-tab-sec">
                            <div className="row">
                                <div className="col-lg-6 col-6 ">
                                    <div className="   img-div">
                                        <img className='ball-img' src={process.env.PUBLIC_URL + '/assets/images/home/sidebar/football.png'} alt="Logo" />
                                        <h5>FootBall</h5>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-6">
                                    <Nav variant="pills" className='justify-content-end mt-2' defaultActiveKey="first">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Winner</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Total</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Handicap</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content-div">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <TournamentFirstCard />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <TournamentFirstCard />
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <TournamentFirstCard />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </section>
        </>
    );
};

export default TournamentMiddle;
