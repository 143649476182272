import React, { useContext, useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './best-slip.css'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Form from 'react-bootstrap/Form';
import classNames from "classnames";
import { NavLink, useNavigate } from 'react-router-dom';
import { Context } from '../../../utils/context';
import { addcashdata, updatecashdata } from '../../../utils/apis/common/Common';
import { postData } from '../../../utils/api';
import { useForm } from 'react-hook-form';
import Succsessfull_Modal from '../../common-components/Modals/Succsessfull_Modal/Succsessfull_Modal';
import LoginModal from '../../common-components/Modals/LoginModal/LoginModal';
import Please_Login_Modal from "../../common-components/Modals/Please_Login_Modal/Please_Login_Modal";
library.add(fas);

const Best_Slip = ({ teamtwo, teamone, runnerId }) => {
  const [cash, setCash] = useState([]);
  const [fetchvalue, setFetchvalue] = useState('');
  const [successmodal, setSuccessmodal] = useState(false);
  const [error, setError] = useState('');
  const { register, formState: { errors } } = useForm();
  const { othersignin, signin, selectedBet } = useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();
  console.log(selectedBet, "selectedBetselectedBet");
  const matchesData = [
    {
      live: true,
      date: 'Mar 30 09:30, Bangladesh - Sri Lanka',
      winner: 'England U-19 (w)',
      time: '2:15',
      payout: cash?.available_balance
    },
  ];

  const updatedcash = async () => {
    const res = await addcashdata();
    if (res?.success) {
      setCash(res?.data);
    }
  }

  const handleSubmit = async (data) => {
    if (data.amount?.trim() === '') {
      setError('Bet Amount is required');
      return;
    }
    try {

      let res = {
        success: false
      }
      var betAmount = fetchvalue / selectedBet.length;
      for (const iterator of selectedBet) {
        const data = {
          amount: betAmount,
          marketId: iterator?.marketId,
          team_two: teamtwo,
          team_one: iterator?.description
        }

        res = await postData('/betting', data);

      }
      if (res?.success) {
        setSuccessmodal(true);
        setTimeout(() => {
          setSuccessmodal(false);
          window.location.reload();

        }, 3000);
      } else {
        console.error("Error response:", res);
      }

    } catch (error) {
      console.error("Error submitting inquiry:", error);
    }
  };

  useEffect(() => {
    updatedcash();
  }, [])

  const handlefetch = (value) => {
    setFetchvalue(value)
  }

  return (
    <>
      <section className="betslipppp-sec">
        <div className="heading">
          <h1>Betslip</h1>

          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="main-tabs">
              <Nav.Item>
                <Nav.Link eventKey="Open">Open</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Settled">Settled</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Open">Open</Tab.Pane>
              <Tab.Pane eventKey="Settled">Settled</Tab.Pane>
            </Tab.Content>
          </Tab.Container> */}
        </div>

        <div className='after-select'>
          {selectedBet?.length === 0 ? (
            <div className='empty-content'>
              <h5>Your betslip is empty</h5>
              <p>Click on odds to add a bet to the betslip</p>
            </div>
          ) : (
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="main-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={() => setModalShow(true)}>Single Bet</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" onClick={() => setModalShow(true)}>Parlay</Nav.Link>
                </Nav.Item>
                <div className='trash-div'>
                  <button><FontAwesomeIcon icon="fa-solid fa-trash" /></button>
                </div>
              </Nav>
              <div className='bonus-div'>
                <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
                <p>Express bonus up to 100%</p>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div>
                    <div>
                      {selectedBet.map((match, index) => (
                        <div className='match-card' key={index}>
                          <div className='upper-div'>
                            <div className='row p-0 me-0'>
                              <div className='col-xxl-3 col-3'>
                                <div className='live-div'>
                                  <p>LIVE</p>
                                </div>
                              </div>
                              <div className='col-xxl-7 col-7'>
                                <div className='match-date'>
                                  <p>{match?.vsdescription}</p>
                                </div>
                              </div>
                              <div className='col-xxl-2 col-2 text-end'>
                                <button><FontAwesomeIcon icon="fa-solid fa-xmark" /></button>
                              </div>
                            </div>
                          </div>
                          <div className='bottom-div p-2'>
                            <div className='row me-0'>
                              <div className='col-xxl-8 col-8'>
                                <p className='winner-text'>{match?.description}</p>
                                <p className='time-text'>{match?.time}</p>
                              </div>
                              <div className='col-xxl-4 col-4 text-end'>
                                <p className='time-text'>{match?.consolidatedPrice?.currentPrice?.decimal}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className='amount-div'>
                        {/* <p className='pay-text'>Possible payout:</p>
                        <p className='amount'> NGN</p> */}
                      </div>
                    </div>
                  </div>
                  <div className='enter-amount-div'>
                    <button onClick={() => handlefetch(100)} className='rup-btn'>100</button>
                    <button onClick={() => handlefetch(200)} className='rup-btn'>200</button>
                    <button onClick={() => handlefetch(300)} className='rup-btn'>300</button>
                    <button className='rup-btn'>MAX</button>
                    <Form>
                      <Form.Group className="my-2" controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Bet Amount"
                          onChange={(e) => {
                            setFetchvalue(e.target.value);
                            setError('');
                          }}
                          value={fetchvalue} />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                      </Form.Group>

                      {signin ? (
                        <button type='button' onClick={handleSubmit} className='login-btn'>Bet</button>
                      ) : (
                        <NavLink to="/login"><button className='login-btn'>Login</button></NavLink>
                      )}
                    </Form>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          )}
        </div>
      </section >
        <Please_Login_Modal show={modalShow} onHide={() => setModalShow(false)} />
      <Succsessfull_Modal show={successmodal} onHide={() => setSuccessmodal(false)} smsText={"Your Bet has been successfully placed!!"} />
    </>
  )
}

export default Best_Slip;
