import React, { useState } from 'react'
import Fantacysidebar from './Fantacysidebar/Fantacysidebar'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Link, Route, Routes } from 'react-router-dom';
import Sidebaroffcanvas from '../common-components/Offcanvas/Sidebaroffcanvas';
import UpcomingCard from './FantacyCards/UpcomingCard';
import LiveCard from './FantacyCards/LiveCard';
// import "./fantacysport.css"

const FantacySport = () => {
    const [show, setShow] = useState(false);
    const [iconchange, setIconchange] = useState(false);

    const handleShow = () => {
        setShow(!show);
        setIconchange(!iconchange);
    };

    const upcomingData = [
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "Preparing",
            prize: "Win iPhone, Smartwatch + $1 Lakh as 1st Prize",
            link: "/fantacy-sport/contest"
        },
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "Preparing",
            prize: "Win iPhone, Smartwatch + $1 Lakh as 1st Prize",
            link: "/fantacy-sport/contest"
        },
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "Preparing",
            prize: "Win iPhone, Smartwatch + $1 Lakh as 1st Prize",
            link: "/fantacy-sport/contest"
        },
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "Preparing",
            prize: "Win iPhone, Smartwatch + $1 Lakh as 1st Prize",
            link: "/fantacy-sport/contest"
        },
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "Preparing",
            prize: "Win iPhone, Smartwatch + $1 Lakh as 1st Prize",
            link: "/fantacy-sport/contest"
        },
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "Preparing",
            prize: "Win iPhone, Smartwatch + $1 Lakh as 1st Prize",
            link: "/fantacy-sport/contest"
        },

    ];

    const livedata = [
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "OE CC 153/4 (10.0)",
            link: "/fantacy-sport/contest"
        },
        {
            title: "European FootBall – Portugal T10",
            team1: "OE CC",
            team1Description: "OE CC bats",
            logo1: '/assets/images/home/sidebar/3537.png',
            team2: "FR CC",
            logo2: '/assets/images/home/sidebar/3537.png',
            status: "OE CC 153/4 (10.0)",
            link: "/fantacy-sport/contest"
        },

    ];
    const completed = [
        {
          title: "European FootBall – Portugal T10",
          team1: "OE CC",
          logo1: "/assets/images/home/sidebar/3537.png",
          team2: "FR CC",
          logo2: "/assets/images/home/sidebar/3537.png",
          status: "OE CC 153/4 (10.0)",
          link: "/fantacy-sport/completed-contest",
        },
        
      ];
    return (
        <>

            <section className="fantacy-section fantacy-sidebar">
                <div className="container-fluid p-0">
                    <div className="sectionflex">
                        <div className='sidebar-header'>
                            <FontAwesomeIcon icon={iconchange ? "fa-xmark " : " fa-bars"} className='bar-icon' onClick={handleShow} />
                            <img className='headlogo' src={process.env.PUBLIC_URL + '/assets/images/logo/logo.png'} alt="Logo" />
                            <Link to="/fantacy-sport/addcash"><button type="button" class="header_btn"><span class="px-1">Add Cash</span> <span className='plus-div'>+</span></button></Link>
                        </div>
                        <div className='first-tab-sec'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="pills" className="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <FontAwesomeIcon icon="fa-solid fa-baseball" />
                                            <p>FootBall</p>
                                        </Nav.Link>
                                    </Nav.Item>

                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">

                                        <div className='second-tab-sec'>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming">
                                                <div className='sec-nav-pill'>
                                                    <Nav variant="pills" className="">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="live">Live</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="completed">Completed</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                                <div className='second-tab-content'>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="upcoming">
                                                            <UpcomingCard events={upcomingData} />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="live">
                                                            <LiveCard livevents={livedata} />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="completed">
                                                        <LiveCard livevents={completed} />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>


                            <Sidebaroffcanvas show={show} placement="start" name="start" setShow={setShow} />


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FantacySport