import React, { useState, useEffect, useContext } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Form from "react-bootstrap/Form";
import "./SignUp.css";
import "../Login/Login.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie'
import Succsessfull_Modal from '../../../src/Components/common-components/Modals/Succsessfull_Modal/Succsessfull_Modal'
import PasswordChecklist from "react-password-checklist";
import { mobileRegister } from "../../utils/common";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { Context } from '../../utils/context'

library.add(fas);
const SignUp = () => {
    const navigate = useNavigate();
    const [successfullModalShow, setSuccessfullModalShow] = useState(false);
    const [noticeModalShow, setNoticeModalShow] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [phone, setPhone] = useState("");
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const { IMG_URL, setuserId, setSignin, othersignin, setOtherSignin } = useContext(Context);
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [emailerror, setemailErrors] = useState("");
    const [contactError, setContactError] = useState("");


    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();


    const onSubmit = async (d) => {
        console.log(d);
        const res = await mobileRegister(d);
        // console.log(res);
        if (res?.success) {
            setemailErrors("")
            setSuccessfullModalShow(true);
            Cookies.set("arc_security", res?.data?.token);
            console.log(Cookies.get("arc_security", "idddddd"));
            setSignin(true);
            setuserId(res?.data?.user);
            setTimeout(() => {
                setSuccessfullModalShow(false);
                setNoticeModalShow(true);
                navigate('/');
            }, 3000);

        } else {
            setemailErrors(res?.response?.data?.data?.mobile_number?.[0]);
        }
    }


    return (
        <>
            <section className='signup-form login-form'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-6 col-xl-7 col-md-8'>
                            <div className='form-div'>
                                <div className='heading'>
                                    <div className='icon'>
                                        <NavLink to="/"><FontAwesomeIcon icon="fa-solid fa-xmark" /></NavLink>
                                    </div>
                                    <div className='heading-main'>
                                        <h1>Sign Up</h1>
                                    </div>
                                    <div className='icon'>
                                        <FontAwesomeIcon icon="fa-solid fa-headset" />
                                    </div>
                                </div>
                                <div className=" mx-5">
                                    <div className="banner-div mb-3">
                                        <img className='bann-img' src={process.env.PUBLIC_URL + '/assets/images/signup/bann.png'} alt="Logo" />
                                        <div className="overlay-text">
                                            <div className="row">
                                                <div className="col-xxl-2 col-xl-2 col-md-2 arrow-div">
                                                    <img className='gift-img' src={process.env.PUBLIC_URL + '/assets/images/signup/gift.gif'} alt="Logo" />
                                                </div>
                                                <div className="col-xxl-8 col-xl-8 col-md-8">
                                                    <p className="surprise-text">We have a surprise for you</p>
                                                    <p className="wel-text">Enjoy Your Welcome Bonus Here</p>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-md-2 arrow-div">
                                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group controlId="Mobile_Number">
                                            <div className="get-otp-text">
                                                {/* <PhoneInput
                                                    country={"ng"}
                                                    value={phone}
                                                    onChange={setPhone}
                                                    {...register("mobile_number", {
                                                        required: "Field is required",
                                                        pattern: {
                                                            value: /^\d{10}$/,
                                                            message: "Please enter a valid 10-digit Contact Number",
                                                        },
                                                    })}
                                                /> */}



                                                <Controller
                                                    name="mobile_number"
                                                    control={control}
                                                    render={({ field }) => (

                                                        <PhoneInput
                                                            country="ng"
                                                            name="mobile_number"
                                                            value={field.value}
                                                            onChange={(value) => field.onChange(value)}
                                                            placeholder="Enter your mobile no."
                                                            inputStyle={{
                                                                borderColor: errors.mobile_number ? "red" : "" ? "green" : "",
                                                            }}

                                                        />


                                                    )}

                                                    rules={{ required: "Mobile number is required" }}
                                                />
                                            </div>
                                        </Form.Group>

                                        {/* <Form.Control
                                                type="text"
                                                placeholder="Enter Phone No"
                                                {...register("mobile_number", {
                                                required: "Field is required",
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10-digit Contact Number",
                                                },
                                                })}
                                            /> */}


                                        <div className="password-input-container mt-3">
                                            <Form.Control
                                                // type={showConfirmPassword ? "text" : "password"}
                                                name="Password"

                                                type={showConfirmPassword ? "text" : "password"}
                                                placeholder="Enter Password"
                                                {...register("password", {
                                                    required: "Password is required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must be at least 8 characters long",
                                                    },
                                                    pattern: {
                                                        // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S.{8,}$/,
                                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*()-+=]{8,}$/,
                                                        message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                                    }
                                                })}
                                                onChange={e => setPassword(e.target.value)}
                                            />

                                            <div
                                                className="password-icon-holder"
                                                onClick={toggleConfirmPasswordVisibility}
                                            >
                                                <FontAwesomeIcon
                                                    className="password-icon"
                                                    icon={showConfirmPassword ? faEyeSlash : faEye}
                                                />
                                            </div>

                                        </div>




                                        {/* <ul className="my-3">
                                        <li>at least 8 symbols</li>
                                        <li>at least 1 digit (0-9)</li>
                                        <li>at least 1 special character</li>
                                        <li>at least 1 character (a-z)</li>
                                        <li>at least 1 uppercase character (A-Z)</li>
                                        </ul> */}
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital"]}
                                            minLength={8}
                                            value={password}
                                            valueAgain={passwordAgain}
                                            onChange={(isValid) => { }}
                                        />
                                        <div>
                                            <span className="text-center" style={{ color: 'red' }}>{emailerror}</span>
                                        </div>

                                        <Accordion >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>I Have a Promo Code</Accordion.Header>
                                                <Accordion.Body>

                                                    <Form.Control
                                                        type="text"
                                                        placeholder="PromoCode"
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>

                                        <div className="checkbox-div">


                                            <div className="row">
                                                <div className="col-xxl-11 col-11">
                                                    <span className="dont-account">By signing up, I hereby confirm that I am over 18, I read and accepted  </span>
                                                    <NavLink> the offer agreements for the chosen currency with the applicable terms and conditions</NavLink>
                                                </div>
                                                <div className="col-xxl-1 col-1 arrow-div">
                                                    <Form.Check
                                                        required
                                                        feedback="You must agree before submitting."
                                                        feedbackType="invalid"
                                                    />
                                                </div>
                                            </div>

                                        </div>


                                        <div className="login-div mx-5">
                                            <button type="submit" className="log signupbtn" >
                                                Sign Up
                                            </button>
                                        </div>



                                        <div className="text-center mb-4">
                                            <span className="dont-account">Don't have an account? </span> <NavLink to="/login"> Log In</NavLink>
                                        </div>
                                    </Form>
                                </div>
                                <Succsessfull_Modal show={successfullModalShow} onHide={() => setSuccessfullModalShow(false)} smsText={'SignUp Successful'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mx-md-5 mx-1">
                  <div className="banner-div mb-3">
                    <img
                      className="bann-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/signup/bann.png"
                      }
                      alt="Logo"
                    />
                    <div className="overlay-text">
                      <div className="row">
                        <div className="col-xxl-2 col-xl-2 col-2 arrow-div">
                          <img
                            className="gift-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/signup/gift.gif"
                            }
                            alt="Logo"
                          />
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-8">
                          <p className="surprise-text">
                            We have a surprise for you
                          </p>
                          <p className="wel-text">
                            Enjoy Your Welcome Bonus Here
                          </p>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-2 arrow-div">
                          <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form>
                    <div className="password-input-container mt-3">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder=" Enter Your  USer Id "
                      />
                    </div>
                    <div className="password-input-container mt-3">
                      <Form.Group controlId="Mobile_Number ">
                        <div className="get-otp-text">
                          <PhoneInput
                            country={"ng"}
                            value={phone}
                            onChange={setPhone}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="password-input-container mt-3">
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        name="Password"
                        placeholder=" Password"
                      />
                      <div
                        className="password-icon-holder"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          className="password-icon"
                          icon={showConfirmPassword ? faEye : faEyeSlash}
                        />
                      </div>
                    </div>
                  </Form>
                  <ul className="my-3">
                    <li>at least 8 symbols</li>
                    <li>at least 1 digit (0-9)</li>
                    <li>at least 1 special character</li>
                    <li>at least 1 character (a-z)</li>
                    <li>at least 1 uppercase character (A-Z)</li>
                  </ul>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>I Have a Promo Code</Accordion.Header>
                      <Accordion.Body>
                        <Form.Control type="text" placeholder="PromoCode" />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="checkbox-div">
                    <div className="row">
                      <div className="col-xxl-11 col-11">
                        <span className="dont-account">
                          By signing up, I hereby confirm that I am over 18, I
                          read and accepted{" "}
                        </span>
                        <NavLink>
                          {" "}
                          the offer agreements for the chosen currency with the
                          applicable terms and conditions
                        </NavLink>
                      </div>
                      <div className="col-xxl-1 col-1 arrow-div">
                        <Form.Check
                          required
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="login-div mx-5">
                  <button className="log ">Sign Up</button>
                </div>
                <div className="text-center mb-4">
                  <span className="dont-account">Don't have an account? </span>{" "}
                  <NavLink to="/login"> Log In</NavLink>
                </div>
         
      </section>
    </>
  );
};

export default SignUp;
