import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import "./Please_Login_Modal.css"

const Please_Login_Modal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-modal Please_Login_Modal"
      >
        <Modal.Body>
          <h4>Login to continue</h4>
          <p>
            This section is available only to registered user. Please login or
            register.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <NavLink to="/signup">
            <button className="signup_btn buttons" onClick={props.onHide}>
              Sign Up
            </button>
          </NavLink>
          <NavLink to="/login">
            <button className="login_btn buttons" onClick={props.onHide}>
              Log in
            </button>
          </NavLink>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Please_Login_Modal;
