import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const emailRegister = async (data) => {
  try {
    const res = await postData(`/emailRegister`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mobileRegister = async (data) => {
  try {
    const res = await postData(`/mobileRegister`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postGame = async (data) => {
  try {
    const res = await postData(`/game`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};


export const changePassword = async (data) => {
  try {
    const res = await postData(`/changePassword`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getLeagues = async (users, token) => {
  try {
    let url = `leagues/?user=${users}&token=${token}&t=list`;

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getCompetitions = async (id) => {
  try {
    let url = `/getCompetitions/${4}`;

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};


export const addcashdata = async (token) => {
  try {
    let url = `/wallet`;

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const updatecashdata = async (users, token) => {
  try {
    let url = `/wallet`;

    return await postData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getEvents = async (eventId) => {
  try {
    let url = `/getEvents/${eventId}`;

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getMarketsList = async (marketId) => {
  try {
    let url = `/geMarketsList/${marketId}`;

    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};

export const getRunners = async (runnerId) => {
  try {
    let url = `/geRunners/${runnerId}`;
    return await getData(url);
  } catch (error) {
    console.log(error);
  }
};