import "./banner/Banner.css";
import Banner from "./banner/Banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./homemain-sec.css";
import Sidebar from "./sidebar/Sidebar";
import Mid_cont from "./middle-content/Mid_cont";
import Best_Slip from "./bestslip/Best_Slip";
import Footer from "../footer/Footer";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context";
import { getCompetitions, getEvents, getRunners } from "../../utils/apis/common/Common";


function Home() {

  const { IMG_URL, setGameId, gameId, token, users } = useContext(Context);
  const [uniLeagues, setUniLeagues] = useState([]);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);
  const [events, setEvents] = useState([]);
  const [runner, setRunner] = useState([]);
  const [runnerId, setRunnerId] = useState([]);
  const [teamone, setTeamOne] = useState([]);
  const [teamtwo, setTeamtwo] = useState([]);
  const getleague = async () => {
    const res = await getCompetitions(gameId);
    console.log(res, "datatata");
    setUniLeagues(res);
    if (res?.length > 0) {
      getEventsdata(res?.[0]?.competition?.id)
    }
  }

  const getEventsdata = async (competitionId) => {
    const res = await getEvents(competitionId);
    console.log(res, "datatata123");
    setEvents(res);
  }

  useEffect(() => {
    getleague();

  }, [gameId]);

  const getrunnersData = async (runnerId) => {
    const res = await getRunners(runnerId);
    console.log(res, "runnerrr");
    setRunner(res)
  }

  useEffect(() => {
    if (setRunnerId) {
      getrunnersData(setRunnerId);

    }

  }, [selectedCompetitionId]);



  useEffect(() => {
    if (selectedCompetitionId) {
      getEventsdata(selectedCompetitionId);

    }

  }, [selectedCompetitionId]);
  return (
    <>
      <section className="home-main-section">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-6 p-0 order-2 order-md-1 ">
              <div className="first-scroll-div">
                <Sidebar setSelectedCompetitionId={setSelectedCompetitionId} uniLeagues={uniLeagues} />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-7  col-md-6 col-sm-12 order-1 order-md-2">
              <div className="scroll-second-div">
                <Mid_cont setRunnerId={setRunnerId} setTeamtwo={setTeamtwo} setTeamOne={setTeamOne} events={events} />
                <Footer />
              </div>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3  col-sm-6 p-0 order-3 order-md-3">
              <div className="third-scroll-div">
                <Best_Slip teamtwo={teamtwo} teamone={teamone} runnerId={runnerId} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
