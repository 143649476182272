import React, { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Context } from "../../utils/context"
import Cookies from 'js-cookie';
library.add(fas);
function Header() {

  // const { postData, setOtherSignin, setUsertype, setuserId, setSignin } = useContext(Context);
  const { othersignin, setSignin, setUsertype, userId, setuserId, signin } = useContext(Context);
  const navigate = useNavigate();

  const handleLogout = async () => {
    Cookies.remove("arc_security", { path: "/" });
    await setSignin(false);
    await setUsertype("");
    await navigate("/login");
  }

  return (
    <>
      <section className='game-header'>
        <Navbar expand="lg" className="main-head">
          <Container fluid>
            <Navbar.Brand as={Link} to={"/"} ><img className='headlogo' src={process.env.PUBLIC_URL + '/assets/images/logo/logo.png'} alt="Logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0"
              >
                <NavLink to="/" className="nav-links"><FontAwesomeIcon icon="fa-solid fa-tv" className="me-2" />  Live Event</NavLink>
                <NavLink to="/match" className="nav-links"><FontAwesomeIcon icon="fa-solid fa-calendar-days" className="me-2" />Upcoming Match</NavLink>
                <NavLink to="/virtualsport" className="nav-links"><FontAwesomeIcon icon="fa-solid fa-baseball" className="me-2" />Virtual Sports</NavLink>
                <NavLink to="/exchange" className="nav-links"><FontAwesomeIcon icon="fa-solid fa-check-to-slot" className="me-2" />Exchange</NavLink>
                <NavLink to="/fantacy-sport/fantfoot" className="nav-links"><FontAwesomeIcon icon="fa-solid fa-futbol" className="me-2" />Fantasy Sports</NavLink>
              </Nav>
              <Form className="d-flex align-items-center">

                <NavLink><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="nav-links" /></NavLink>
                {signin ? (
                  <>
                    <NavLink to="/login"><button className="login-btn" onClick={handleLogout}>Logout</button></NavLink>
                    {/* <NavLink to="/signup"><button className="sign-up-btn">Sign Up</button></NavLink> */}
                  </>
                ) :
                  (
                    <>
                      <NavLink to="/login"><button type="button" className="login-btn">Login</button></NavLink>
                      <NavLink to="/signup"><button type="button" className="sign-up-btn">Sign Up</button></NavLink>
                    </>
                  )

                }

              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>
    </>
  );
}

export default Header;
