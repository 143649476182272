import React, { useState, useContext } from "react";
import './Login.css'
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Context } from '../../utils/context'
import Cookies from 'js-cookie';
import Succsessfull_Modal from "../common-components/Modals/Succsessfull_Modal/Succsessfull_Modal";

library.add(faEye, faEyeSlash);

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { postData, setOtherSignin, setUsertype, setuserId, setSignin } = useContext(Context);
    const [emailerror, setemailErrors] = useState("");
    const [successfullModalShow, setSuccessfullModalShow] = useState(false);
    const [noticeModalShow, setNoticeModalShow] = useState(false);
    const navigate = useNavigate();
    const { control, register, handleSubmit, setError, formState: { errors }, reset } = useForm();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (data) => {
        const res = await postData(`/mobileLogin`, data);
        if (res?.success) {
            Cookies.set("userID", res?.data?.user);
            Cookies.set("arc_security", res.data.token, { expires: 1 });
            reset();
            setemailErrors('');
            setSuccessfullModalShow(true);
            setSignin(true);
            setuserId(res?.data?.user);
            setTimeout(() => {
                setSuccessfullModalShow(false);
                setNoticeModalShow(true);
                navigate('/');
            }, 3000);
        } else {
            setemailErrors(res?.response?.data?.data);

        }
    }

    const handleLogout = async () => {
        
    }

    return (
        <>
            <section className='login-form'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-6 col-xl-7 col-md-8'>
                            <div className='form-div'>
                                <div className='heading'>
                                    <div className='heading-main'>
                                        <h1>Log In</h1>
                                    </div>
                                    <div className='icon'>
                                        <FontAwesomeIcon icon="fa-solid fa-headset" />
                                    </div>
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" className="justify-content-center pills-sec">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Phone number</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className='mx-5 my-3'>
                                            <Tab.Pane eventKey="first">
                                                <div className="form-field">
                                                    <Form.Group controlId="Mobile_Number">
                                                        <div className="get-otp-text">
                                                            <Controller
                                                                name="mobile_number"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <PhoneInput
                                                                        country="ng"
                                                                        value={field.value}
                                                                        onChange={field.onChange}
                                                                        placeholder="Enter your mobile no."
                                                                        inputStyle={{
                                                                            borderColor: errors.mobile_number ? "red" : "green",
                                                                        }}
                                                                    />
                                                                )}
                                                                rules={{ required: "Mobile number is required" }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <div className="password-input-container mt-3">
                                                        <Form.Control
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder="Enter Password"
                                                            {...register("password", {
                                                                required: "Password is required",
                                                            })}
                                                            style={{
                                                                borderColor: errors.password ? "red" : "green",
                                                            }}
                                                        />
                                                        <div
                                                            className="password-icon-holder"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            <FontAwesomeIcon
                                                                className="password-icon"
                                                                icon={showPassword ? faEyeSlash : faEye}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <div>
                                                <span className="text-center" style={{ color: 'red' }}>{emailerror}</span>
                                            </div>
                                        </Tab.Content>
                                    </Tab.Container>
                                    <div className="login-div mx-5">
                                        <button type="submit" className="log signupbtn">
                                            Login
                                        </button>
                                    </div>
                                </Form>
                                <div className="text-center mb-4">
                                    <span className="dont-account">Don't have an account? </span>
                                    <NavLink to="/signup">Sign up</NavLink>
                                </div>
                            </div>
                        </div>
                        <Succsessfull_Modal show={successfullModalShow} onHide={() => setSuccessfullModalShow(false)} smsText={'Login Successful'} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;
